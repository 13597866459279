import React, { useRef, useState } from "react";
import {
  useDebounce,
  useDidMount,
  Nullable,
  SvgIcon,
  Vec2,
  clsx,
  Button,
  useDidUpdate,
} from "@gemlightbox/core-kit";
import { AspectRatiosType } from "../camera.types";

import { ReactComponent as One2OneSVG } from "src/external-ts/assets/images/camera/1_1-icon.svg";
import { ReactComponent as Nine2SixteenSVG } from "src/external-ts/assets/images/camera/9_16-icon.svg";
import { ReactComponent as Four2FiveSVG } from "src/external-ts/assets/images/camera/4_5-icon.svg";
import { ReactComponent as Sixteen2NineSVG } from "src/external-ts/assets/images/camera/16_9-icon.svg";
import { ReactComponent as Four2ThreeSVG } from "src/external-ts/assets/images/camera/4_3-icon.svg";
import { ReactComponent as OnePointNineOne2OneSVG } from "src/external-ts/assets/images/camera/1.91_1-icon.svg";
import { ReactComponent as PageArrowSVG } from "src/external-ts/assets/images/camera/page-arrow-icon.svg";
import styles from "./resolution-select.module.css";
import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";

const resolutionData = [
  { name: AspectRatiosType.One2One, src: One2OneSVG },
  { name: AspectRatiosType.Four2Three, src: Four2ThreeSVG },
  { name: AspectRatiosType.Sixteen2Nine, src: Sixteen2NineSVG },
  { name: AspectRatiosType.Four2Five, src: Four2FiveSVG },
  { name: AspectRatiosType.Nine2Sixteen, src: Nine2SixteenSVG },
  { name: AspectRatiosType.OnePointNineOne2One, src: OnePointNineOne2OneSVG },
];

interface ResolutionSelectProps {
  className?: string;
  selectedOption?: string | undefined;
  onChange?: (value: string) => void;
}

export const ResolutionSelect: React.FC<ResolutionSelectProps> = ({
  className,
  selectedOption,
  onChange,
}) => {
  const { debounce } = useDebounce(100);

  const handleChange = (option: string) => {
    if (selectedOption === option) return;

    debounce(() => onChange?.(option));
  };
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [clientWidth, setClientWidth] = useState<number>(0);
  const pesolutionSelectRef = useRef<HTMLDivElement>(null);
  const selectedItemRef = useRef<HTMLDivElement>(null);
  const canGoPrev: boolean = scrollLeft > 0;
  const canGoNext: boolean =
    scrollWidth > clientWidth && !(scrollLeft + clientWidth >= scrollWidth);

  const handleScroll = (left: number) => {
    if (pesolutionSelectRef.current) {
      pesolutionSelectRef.current.scrollTo({
        left,
        behavior: "smooth",
      });
    }
  };

  useDidMount(() => {
    setScrollWidth(pesolutionSelectRef?.current?.scrollWidth || 0);
    setClientWidth(pesolutionSelectRef?.current?.clientWidth || 0);
    selectedItemRef?.current?.scrollIntoView({ behavior: "smooth", inline: "center" });
  });

  useDidUpdate(() => {
    selectedItemRef?.current?.scrollIntoView({ behavior: "smooth", inline: "center" });
  }, [selectedItemRef?.current, selectedOption]);

  return (
    <div className={clsx(styles.resolutionSelectWrapper, className)}>
      <div
        className={clsx(styles.resolutionSelectContainer, globalStyles.hideScrollBar)}
        ref={pesolutionSelectRef}
        onScroll={(e) => {
          setScrollLeft((e.target as HTMLDivElement).scrollLeft);
        }}
      >
        {resolutionData.map((item, index) => {
          const SvgComponent = item.src;
          return (
            <div
              key={index}
              className={clsx(
                styles.resolutionItem,
                selectedOption === item.name && styles.selected,
              )}
              ref={selectedOption === item.name ? selectedItemRef : undefined}
              onClick={() => handleChange(item.name)}
            >
              <SvgComponent />
            </div>
          );
        })}
      </div>
      {canGoPrev && (
        <div className={clsx(styles.pageArrowButtonWrapper, styles.leftPageArrowButtonWrapper)}>
          <div className={styles.pageArrowGradient}></div>
          <Button
            className={styles.pageArrowButton}
            appearance="unset"
            size="unset"
            onClick={() => handleScroll(0)}
          >
            <SvgIcon size={10} icon={PageArrowSVG} />
          </Button>
        </div>
      )}
      {canGoNext && (
        <div className={styles.pageArrowButtonWrapper}>
          <div className={styles.pageArrowGradient}></div>
          <Button
            className={styles.pageArrowButton}
            appearance="unset"
            size="unset"
            onClick={() => handleScroll(pesolutionSelectRef?.current?.scrollWidth || 0)}
          >
            <SvgIcon size={10} icon={PageArrowSVG} />
          </Button>
        </div>
      )}
    </div>
  );
};
