import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { FileUpload, Modal, ObjectType, Heading, Typography } from "@gemlightbox/core-kit";

import { PRODUCTS_IMPORT_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { processFileData, processFiles } from "./products-import-modal.utils";
import { acceptedFiles } from "./products-import-modal.constants";

import styles from "./products-import-modal.module.css";

export interface ProductsImportModalProps {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed?: VoidFunction;
  options: ObjectType;
}

export const ProductsImportModal: React.FC<ProductsImportModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const navigate = useNavigate();
    const { attributesStore, modalsStore, productsImportStore, localeStore } = useStores();

    const handleAccept = async (files: File[]) => {
      try {
        const [file] = files;
        const fileName = file.name;
        const fileData = await processFiles(files);
        const data = processFileData(fileData);

        productsImportStore.setupState(attributesStore.attributes, data);
        modalsStore.close("ProductsImportModal");
        navigate(PRODUCTS_IMPORT_PAGE.path, {
          state: {
            fileName,
          },
        });
      } catch (e) {
        console.error("Error while processing products files: ", e);
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        contentClassName={styles.modalContent}
        withCross
        disableBorderRadius
      >
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('products.modals["products-import-modal"].title')}
        </Heading>
        <Typography className={styles.subtitle} size="small" color="textTertiary">
          {localeStore.t('products.modals["products-import-modal"].description.first')}
          <a
            className={styles.link}
            href="import-product-template.xlsx"
            download="import-product-template.xlsx"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {localeStore.t('products.modals["products-import-modal"].description.link')}{" "}
          </a>
          {localeStore.t('products.modals["products-import-modal"].description.last')}
        </Typography>

        <FileUpload
          title={localeStore.t('media.modals["upload-media-modal"]["file-upload"].title')}
          subtitle={localeStore.t(
            'products.modals["products-import-modal"]["file-uploads-subtitle"]',
          )}
          orText={localeStore.t('media.modals["upload-media-modal"]["file-upload"]["or-text"]')}
          browseButtonText={localeStore.t(
            'media.modals["upload-media-modal"]["file-upload"]["browse-button-text"]',
          )}
          onDropAccepted={handleAccept}
          accept={acceptedFiles}
        />
      </Modal>
    );
  },
);
