import React from "react";
import JsCrypto from "crypto-js";
import { makeAutoObservable, runInAction } from "mobx";
import { Nullable, Webgl2New, base64ToBlob, isDeepEmpty, isMac } from "@gemlightbox/core-kit";
import { isFirstEnterGemCamLocalKey, useStores } from "src/hooks";
import { joinPaths, sizeTobytes } from "src/utils/common.utils";

import { CameraRenderer } from "./camera.renderer";
import {
  DEFAULT_CAPTURE_FRAME_RATE,
  photoResolutionValues,
  videoResolutionValues,
  marketplaceDimensionsValues,
  turnTableBluetoothServiceUUID,
  turnTableBluetoothCharacteristicUUID,
  turnTableDirectionControl,
  turnTableDegreesControl,
  turnTableSpeedControl,
  focusConstraint,
  cameraSettingsLocalStorageEntity,
  cameraFilterModeLocalStorageEntity,
  cameraDpiLocalStorageEntity,
  cameraLogoWatermarkModeLocalStorageEntity,
  cameraLocalSaveSettingsStorageEntity,
  defaultMiniStorageSpace,
  cameraCaptureModeStorageEntity,
} from "./camera.constants";
import { gemAIMap } from "./camera-controls/camera-controls.constants";
import {
  CameraMediaType,
  CameraResolutionType,
  CameraSettingsType,
  CaptureModeType,
  ConnectionStatusKeys,
  FilterMode,
  FilterModeKeys,
  FilterScenarioMode,
  GemAIMapKeys,
  GemAIMapType,
  LocalSaveSettingsType,
  SupportedAdjustments,
  TurnTableDegreesControlKeys,
  TurnTableDirectionControlKeys,
  TurnTableSpeedControlKeys,
} from "./camera.types";
import LocalStorage, { STORAGE_TYPES } from "src/common/helpers/local-storage.helper";
import { DeviceModel } from "src/models";
import {
  pushDataLayerEvent,
  pushRemoveGemCamSuperPropertiesEvent,
  pushUpdateContextPropertiesGemCamDeviceInfoEvent,
  pushUpdateContextPropertiesLocalSaveSettingsEvent,
  pushUpdateContextPropertiesTurntableInfoEvent,
  pushUserAttributeDataLayerEvent,
} from "src/utils";
import { CameraExposureTimeRes, DriveInfo, USBDeviceConnectRes } from "src/store/app-callback";
import { logoWatermarkModalStore } from "./logo-watermark-modal/logo-watermark-modal.store";

export const cameraStorage = new LocalStorage(STORAGE_TYPES.CAMERA_DATA);
const localSaveSettingsStorage = new LocalStorage(cameraLocalSaveSettingsStorageEntity);
const captureModeStorage = new LocalStorage(cameraCaptureModeStorageEntity);
const focusConstraintFinal = focusConstraint;
const { appCallbackStore, userStore } = useStores();
const isApp = window.$platform.isApp;

class CameraStore {
  public videoRef: React.RefObject<HTMLVideoElement>;

  private CAMERA_NAME = ["SPCA2688 AV Camera", "GemCam", "GemCam V2", "GemCam V3", "GemView"];

  public name: string;

  private _parentRef: HTMLElement | null;

  private _usbDevice: USBDeviceConnectRes;
  public get usbDevice() {
    return this._usbDevice;
  }

  private _localSaveSettings: LocalSaveSettingsType = {
    isLocalSaveMode: false,
    miniStorageSpace: 100,
    storageSpaceUnit: "MB",
  };
  public get localSaveSettings() {
    return this._localSaveSettings;
  }

  private _isShowCamera = false;
  public get isShowCamera() {
    return this._isShowCamera;
  }

  private _driveInfo?: DriveInfo | null;
  public get driveInfo() {
    return this._driveInfo;
  }

  private _filterValue: FilterMode = FilterMode.GemLightboxPro;
  public get filterValue() {
    const filterModeLocalStorage = localStorage.getItem(cameraFilterModeLocalStorageEntity);
    if (filterModeLocalStorage) {
      this._filterValue = Number(filterModeLocalStorage) as FilterMode;
    }
    return this._filterValue;
  }

  private _filterScenarioValue: FilterScenarioMode = FilterScenarioMode.Default;
  public get filterScenarioValue() {
    return this._filterScenarioValue;
  }

  public get generateAIDescription() {
    return this._gemAIData.description[this._filterValue].value;
  }

  public get presetMode() {
    return this._gemAIData.background[this._filterValue].value
      ? "background"
      : this._gemAIData.retouch[this._filterValue].value
      ? "retouch"
      : "";
  }

  private _gemAIData: GemAIMapType;
  public get gemAIData() {
    return this._gemAIData;
  }

  private _gemcamPreviewVideo: CameraMediaType | null;
  public get gemcamPreviewVideo() {
    return this._gemcamPreviewVideo;
  }

  private _isBlockCamera = false;
  public get isBlockCamera() {
    return this._isBlockCamera;
  }

  private _isBurstMode = true;
  public get isBurstMode() {
    return this._isBurstMode;
  }

  private _isLogoWatermarkMode = false;
  public get isLogoWatermarkMode() {
    const isLogoWatermarkModeStorage = localStorage.getItem(
      cameraLogoWatermarkModeLocalStorageEntity,
    );
    if (isLogoWatermarkModeStorage) {
      this._isLogoWatermarkMode = JSON.parse(isLogoWatermarkModeStorage);
    }
    return this._isLogoWatermarkMode;
  }

  private _gemAIActive = true;
  public get gemAIActive() {
    return this._gemAIActive;
  }

  private _bluetoothConnecting = false;
  public get bluetoothConnecting() {
    return this._bluetoothConnecting;
  }

  private _bluetoothStatus = 0;
  public get bluetoothStatus() {
    return this._bluetoothStatus;
  }

  private _bluetoothAutoConnect = false;
  public get bluetoothAutoConnect() {
    return this._bluetoothAutoConnect;
  }

  private _bluetoothDeviceList: DeviceModel[];
  public get bluetoothDeviceList() {
    return this._bluetoothDeviceList;
  }

  private _bluetoothDeviceListWeb: BluetoothDevice[];
  public get bluetoothDeviceListWeb() {
    return this._bluetoothDeviceListWeb;
  }

  private _bluetoothDevice: DeviceModel | null;
  private _bluetoothDeviceWeb: BluetoothDevice | null;

  public get bluetoothDeviceSelected() {
    return isApp ? this._bluetoothDevice : this._bluetoothDeviceWeb;
  }

  private _videoSpeed: TurnTableSpeedControlKeys = 0;
  public get videoSpeed() {
    return this._videoSpeed;
  }

  private _videoAngle: TurnTableDegreesControlKeys = 360;
  public get videoAngle() {
    return this._videoAngle;
  }

  private _resolutionVideo: CameraResolutionType;
  public get resolutionVideo() {
    return this._resolutionVideo;
  }

  private _resolutionPhoto: CameraResolutionType;
  public get resolutionPhoto() {
    return this._resolutionPhoto;
  }

  private _captureMode: "video" | "photo" = "photo";
  public get captureMode() {
    return this.captureModeStorageData?.captureMode ?? this._captureMode;
  }

  public get captureModeStorageData() {
    return (
      (captureModeStorage.get() as {
        captureMode?: CaptureModeType;
        resolutionVideo?: CameraResolutionType;
        resolutionPhoto?: CameraResolutionType;
      }) ?? null
    );
  }

  public getAspectRatio(resolution: CameraResolutionType) {
    const [w, h] = cameraStore.getResolutionByCameraResolutionValue(resolution);
    const _aspectRatio = logoWatermarkModalStore.getAspectRatio(w, h);
    return _aspectRatio;
  }

  private _dpi: number;
  public get dpi() {
    const dpiStorage = localStorage.getItem(cameraDpiLocalStorageEntity);
    if (dpiStorage) {
      this._dpi = parseInt(dpiStorage);
    }
    return this._dpi;
  }

  private _resolutionMode: "marketplace" | "regular" = "regular";
  public get resolutionMode() {
    return this._resolutionMode;
  }

  private _mediaRecorder: MediaRecorder | null = null;
  public get mediaRecorder() {
    return this._mediaRecorder;
  }

  private _isRecording = false;
  public get isRecording() {
    return this._isRecording;
  }

  private _recordedTime = 0;
  public get recordedTime() {
    return this._recordedTime;
  }

  private _videoConstraints: MediaTrackConstraints;

  private _focusMode: string;
  public get focusMode() {
    return this._focusMode;
  }

  private _focusDistance: number;
  public get focusDistance() {
    return this._focusDistance;
  }

  private _exposureTime: number;
  public get exposureTime() {
    return this._exposureTime;
  }

  private _exposureTimeRes: CameraExposureTimeRes;
  public get exposureTimeRes() {
    return this._exposureTimeRes;
  }

  private _track: Nullable<MediaStreamTrack>;

  private _mediaStream: Nullable<MediaStream>;

  private _connectionStatus: ConnectionStatusKeys;
  public get connectionStatus() {
    return this._connectionStatus;
  }

  public renderer: CameraRenderer;
  public get camera() {
    return this.renderer?.camera;
  }
  public get cameraScale() {
    return this.camera?.scale ?? 1;
  }

  private _mainImageComponentScale = 1;
  public get mainImageComponentScale() {
    return this._mainImageComponentScale;
  }

  private _bluetoothCharacteristic: BluetoothRemoteGATTCharacteristic | undefined;
  public get bluetoothCharacteristic() {
    return this._bluetoothCharacteristic;
  }

  private _bluetoothCommand = "";
  public get bluetoothCommand() {
    return this._bluetoothCommand;
  }

  public mainImageComponent: Webgl2New.ImageAdjustRectComponent;

  public logoWatermarkImageComponent: Webgl2New.ImageAdjustRectComponent[];

  public get isOpendLogoWatermarkWindow() {
    return logoWatermarkModalStore.logoWatermarkList.length === 0;
  }

  private _animationFrameId = 0;

  private _recommendedResolutions: number[];
  public get recommendedResolutions() {
    if (this._recommendedResolutions && this._recommendedResolutions.length > 0)
      return this._recommendedResolutions;

    const value = localStorage.getItem("recommendedResolutions");
    const recommendedResolutions = (value ? JSON.parse(value) : []) as number[];
    runInAction(() => {
      this._recommendedResolutions = recommendedResolutions;
    });
    return this._recommendedResolutions;
  }

  private _resDetPctVal: number;
  public get resDetPctVal() {
    return this._resDetPctVal;
  }

  private _stopScanBuletoothDeviceTimerId: NodeJS.Timeout;

  private _cameraSettings: CameraSettingsType | null;
  public get cameraSettings() {
    return this._cameraSettings;
  }

  private _isGeneratedSkuTutorialOpened = false;
  public get isGeneratedSkuTutorialOpened() {
    return this._isGeneratedSkuTutorialOpened;
  }

  private _isNoSaveProductInfo = false;
  public get isNoSaveProductInfo() {
    return this._isNoSaveProductInfo;
  }

  private _isFilterSceneTutorialOpened = false;
  public get isFilterSceneTutorialOpened() {
    return this._isFilterSceneTutorialOpened;
  }

  constructor() {
    this._initStore();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public async mount(parent: HTMLElement, upgradeCb?: () => void) {
    this._parentRef = parent;
    try {
      await this.requestCameraPermissin();
      if (window.$platform.isApp && window.$platform.os === "Win") {
        await this.recordTestDataForAllResolutions();
        this.setBestResolution();
      } else {
        this.setBestResolution();
      }
    } catch (error) {
      console.error("error: you are in web platform");
    }
    runInAction(() => {
      this.renderer = new CameraRenderer();
    });

    const webglPromise = Webgl2New.Webgl2.initialize()
      .then((res) => res)
      .catch((error) => {
        // 弹窗提示升级
        typeof upgradeCb === "function" && upgradeCb();
        console.error(error);
      });
    const cameraPromise = this.checkAndPlayStream();
    pushUpdateContextPropertiesTurntableInfoEvent(this.bluetoothDeviceSelected?.name);
    pushUpdateContextPropertiesLocalSaveSettingsEvent(this.localSaveSettings);
    logoWatermarkModalStore
      .rendererLogoWatermarkByCameraRenderer(this.renderer.canvasSize)
      .then((res) => {
        this.logoWatermarkImageComponent = res;
      });
    return Promise.all([webglPromise, cameraPromise]).then(() => {
      const video = this.videoRef.current;
      if (!video) return;
      this.renderer.mount(parent, video.videoWidth, video.videoHeight);
      this._handleInitializeWebgl2();
      this.play();
      this.usbDetect();
    });
  }

  public unmount() {
    this.renderer.unmount();
    this.stopCamera();
    this._initStore();
    pushRemoveGemCamSuperPropertiesEvent();
  }

  // 相机权限请求
  public requestCameraPermissin() {
    return new Promise((resolve, reject) => {
      if (isApp) {
        appCallbackStore.getCameraAuthStatus(
          (data) => {
            runInAction(() => {
              const isBlock = data.cameraAuthStatus === 2;
              this._isBlockCamera = isBlock;
              isBlock && (this._connectionStatus = "notConnected");
              resolve("");
            });
          },
          (error) => {
            reject(error);
          },
        );
      } else {
        const permissionName = "camera" as PermissionName;
        navigator.permissions
          .query({ name: permissionName })
          .then((result) => {
            if (result.state === "prompt") {
              result.addEventListener("change", (event) => {
                runInAction(() => {
                  const isBlock = event.target?.state === "denied";
                  this._isBlockCamera = isBlock;
                  isBlock && (this._connectionStatus = "notConnected");
                });
              });
            } else if (result.state === "denied") {
              runInAction(() => {
                this._isBlockCamera = true;
                this._connectionStatus = "notConnected";
              });
            }
            resolve("");
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  public cameraDeviceConnected() {
    if (isApp) {
      appCallbackStore.getCameraDeviceList((data) => {
        if (data && Array.isArray(data[0])) data = data[0];

        if (!data || data.length == 0) return this.stopCamera();

        const theFirstDevice = data.find((item) => {
          return this.CAMERA_NAME.some((name) =>
            item.cameraName?.toLowerCase().startsWith(name.toLowerCase()),
          );
        });

        if (theFirstDevice) {
          runInAction(() => (this._usbDevice = theFirstDevice));
          pushUpdateContextPropertiesGemCamDeviceInfoEvent(theFirstDevice);
          pushUserAttributeDataLayerEvent({
            last_gemcam_serial_number: theFirstDevice?.cameraSerialNumber,
          });
          pushDataLayerEvent({ event: "gemhub:gemcam:connected" });
        } else {
          this.stopCamera();
        }
      });
    } else {
      pushUpdateContextPropertiesGemCamDeviceInfoEvent({
        cameraName: this.name,
      });
      pushDataLayerEvent({ event: "gemhub:gemcam:connected" });
    }
  }

  // 设置相机是否自动对焦
  public setFocusMode(mode: string, needReset = true) {
    if (needReset) {
      runInAction(() => {
        this._focusMode = mode;
      });
    }

    if (isApp) {
      return new Promise<void>((resolve) => {
        appCallbackStore.setCameraFocusMode(
          mode === "continuous",
          "",
          () => {
            if (mode === "manual" && isMac()) {
              appCallbackStore.getCameraFocusValue("", (res) => {
                const { focusDistance } = {
                  focusDistance: res.focusCurrentValue,
                } || {
                  focusDistance: this._focusDistance || focusConstraintFinal.focusDistance.min,
                };
                this.setFocusDistance(focusDistance).then(() => {
                  resolve();
                });
              });
              return;
            }

            resolve();
          },
          () => {
            resolve();
          },
        );
      });
    } else {
      return new Promise<void>((resolve) => {
        this._track
          ?.applyConstraints({
            ...this._videoConstraints,
            advanced: [{ focusMode: mode }] as any,
          })
          .then(() => {
            if (mode === "manual") {
              const { focusDistance } = this._track?.getSettings() || {
                focusDistance: this._focusDistance || focusConstraintFinal.focusDistance.min,
              };
              this.setFocusDistance(focusDistance).then(() => {
                resolve();
              });
              return;
            }

            resolve();
          })
          .catch(() => resolve());
      });

      // const controlTransfer: USBControlTransferParameters = {
      //   requestType: "class",
      //   recipient: "interface",
      //   request: 1,
      //   value: 2048,
      //   index: 256,
      // };

      // const sendData = new Int8Array(8);
      // sendData[0] = 0;

      // this._usbDevice
      //   .controlTransferOut(controlTransfer, sendData)
      //   .then(() => {
      //     console.log("Focus mode set to manual successfully!");
      //   })
      //   .catch((error) => {
      //     console.error("Error setting focus mode to manual:", error);
      //   });
    }
  }

  // 设置相机焦距
  public setFocusDistance(distance: number) {
    this._focusDistance = distance;
    if (isApp) {
      return new Promise<void>((resolve) => {
        appCallbackStore.setCameraFoucusValue(
          distance,
          "",
          () => {
            resolve();
          },
          () => {
            resolve();
          },
        );
      });
    } else {
      return new Promise<void>((resolve) => {
        this._track
          ?.applyConstraints({
            ...this._videoConstraints,
            advanced: [{ focusDistance: distance }] as any,
          })
          .then(() => {
            resolve();
          })
          .catch(() => resolve());
      });
    }
  }

  // 设置相机曝光时长
  public setCameraExposureTime(exposureTime = 12) {
    if (isApp) {
      appCallbackStore.setCameraExposureTime(exposureTime);
      this._exposureTime = exposureTime;
    }
  }

  public getCameraExposureTime() {
    if (isApp) {
      appCallbackStore.getCameraExposureTime("", (res) => {
        this._exposureTimeRes = res;
      });
    }
  }

  // 重置相机曝光时长
  public resetCameraExposure() {
    if (isApp) {
      appCallbackStore.resetCameraExposure();
    }
  }

  // 重置相机所有设置
  public resetCameraSetting() {
    return new Promise<void>((resolve) => {
      if (isApp) {
        appCallbackStore.resetCameraSetting(
          "",
          () => {
            resolve();
          },
          () => {
            resolve();
          },
        );
      } else {
        resolve();
      }
    });
  }

  public setLocalSaveSettings(obj: LocalSaveSettingsType) {
    if (obj && obj?.folderPath)
      localSaveSettingsStorage.set({
        ...obj,
        folderPath: JsCrypto.AES.encrypt(
          obj.folderPath,
          cameraLocalSaveSettingsStorageEntity,
        ).toString(),
      });
    runInAction(() => {
      this._localSaveSettings = obj;
    });
    pushUpdateContextPropertiesLocalSaveSettingsEvent(obj);
  }

  public setIsLocalSaveMode(value: boolean) {
    const localSaveSettings = localSaveSettingsStorage.get() as LocalSaveSettingsType;
    if (localSaveSettings && localSaveSettings?.folderPath) {
      localSaveSettings.isLocalSaveMode = value;
      localSaveSettingsStorage.set(localSaveSettings);
      runInAction(() => (this._localSaveSettings.isLocalSaveMode = value));
      pushUpdateContextPropertiesLocalSaveSettingsEvent({
        ...this.localSaveSettings,
        isLocalSaveMode: value,
      });
    }
  }

  public selectFolder() {
    if (isApp) {
      appCallbackStore.selectFolder(
        (data) => {
          runInAction(() => {
            this._driveInfo = data;
          });
        },
        (msg, data) => {
          this._driveInfo = data;
          console.log(msg);
        },
      );
    }
  }

  public getDriveInfo() {
    return new Promise<void>((resolve) => {
      if (isApp && this.localSaveSettings?.folderPath) {
        appCallbackStore.getDriveInfo(
          this.localSaveSettings?.folderPath,
          (data) => {
            runInAction(() => {
              this._driveInfo = data;
            });
            resolve();
          },
          (msg) => {
            runInAction(() => {
              this._driveInfo = null;
            });
            resolve();
          },
        );
      } else {
        resolve();
      }
    });
  }

  public isLocalStorageSettingsAvailable(blob?: Blob | string) {
    let filesStoreSize = 0;
    // In fact, I need to get the latest disk information every time, and this asynchrony will cause some problems
    // await this.getDriveInfo();
    if (blob) {
      if (typeof blob === "string") {
        filesStoreSize = base64ToBlob(blob.split(",")[1]).size;
      } else {
        filesStoreSize = blob.size;
      }
    }

    if (this.localSaveSettings?.useCustom) {
      return (
        sizeTobytes(
          this.localSaveSettings.miniStorageSpace,
          this.localSaveSettings.storageSpaceUnit,
        ) +
          filesStoreSize <
        (this.driveInfo?.availableFreeSpace || 0)
      );
    } else {
      return (
        sizeTobytes(defaultMiniStorageSpace.miniStorageSpace, defaultMiniStorageSpace.Unit) +
          filesStoreSize <
        (this.driveInfo?.availableFreeSpace || 0)
      );
    }
  }

  public saveLocalStorage(
    fileName: string,
    blob: Blob | string,
    success?: () => void,
    fail?: (msg: string) => void,
  ) {
    Promise.resolve().then(() => {
      if (isApp && this.localSaveSettings.isLocalSaveMode && this.localSaveSettings?.folderPath) {
        if (typeof blob === "string") {
          appCallbackStore?.saveLocalStorage?.(
            joinPaths(this.localSaveSettings.folderPath, fileName),
            blob.split(",")[1],
            success,
            fail,
          );
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            const fileContent = e.target?.result;
            if (!fileContent) return;
            appCallbackStore?.saveLocalStorage?.(
              joinPaths(this.localSaveSettings.folderPath!, fileName),
              String(fileContent).split(",")[1],
              success,
              fail,
            );
          };
          reader.readAsDataURL(blob);
        }
      }
    });
  }

  // usb设备探测
  public usbDetect() {
    if (isApp) {
      appCallbackStore.cameraDeviceDidConnected((data) => {
        // 1. 插入额外 gemcam，仍然保持与原 gemcam 连接
        //    记录已连接设备 id，若当前还有连接设备，则直接 return
        // 2. 拔下已连接 gemcam，仍然保持其他 gemcam 连接
        //    记录下所有已连设备 id，监听设备 ended 事件，自动切换在线设备
        // 3. 初始没有连接任何 gemcam，插入新设备自动连接（已实现）
        const isGemCamera = this.CAMERA_NAME.some((name) => data.cameraName.startsWith(name));
        if (isGemCamera) {
          // 重新检测相机
          if (!this._parentRef) return;
          this.mount(this._parentRef);
        }
      });
    } else {
      // if ("usb" in navigator) {
      //   navigator.usb
      //     .requestDevice({
      //       filters: [
      //         {
      //           // productId: 533,
      //           vendorId: 7119,
      //         },
      //       ],
      //     })
      //     .then((device) => {
      //       this._usbDevice = device;
      //       return device.open();
      //     })
      //     .then(() => {
      //       console.log("configurations: ", this._usbDevice.configurations);
      //       this._usbDevice.selectConfiguration(1);
      //     })
      //     .then(() => {
      //       this._usbDevice.claimInterface(0);
      //     });
      // } else {
      //   console.error("USB is not supported in this browser");
      // }
    }
  }

  // 蓝牙权限请求
  public async bluetoothConnectTurnTable() {
    if (isApp) {
      return new Promise<number>((resolve, reject) => {
        appCallbackStore.getBuletoothAuthStatus(
          (status) => {
            runInAction(() => {
              this._bluetoothStatus = status || 0;
            });

            resolve(this._bluetoothStatus);
          },
          (error) => {
            runInAction(() => {
              this._bluetoothStatus = 2;
            });
            reject(error);
          },
        );
      });
    } else {
      try {
        const bluetoothDeviceListWeb: BluetoothDevice[] = [];

        const device = await navigator.bluetooth.requestDevice({
          filters: [
            {
              services: [turnTableBluetoothServiceUUID],
            },
          ],
        });

        if (!device) {
          runInAction(() => {
            this._bluetoothDeviceListWeb = bluetoothDeviceListWeb;
            this._bluetoothStatus = 2;
            this._bluetoothDeviceWeb = null;
          });
          return this._bluetoothStatus;
        }

        runInAction(() => {
          this._bluetoothConnecting = true;
        });

        bluetoothDeviceListWeb.push(device);

        // 连接蓝牙
        const server = await device.gatt?.connect();
        const service = await server?.getPrimaryService(turnTableBluetoothServiceUUID);
        const characteristic = await service?.getCharacteristic(
          turnTableBluetoothCharacteristicUUID,
        );
        this._bluetoothCharacteristic = characteristic;
        await this._bluetoothCharacteristic?.startNotifications();

        runInAction(() => {
          this._bluetoothDeviceListWeb = bluetoothDeviceListWeb;
          this._bluetoothStatus = 5;
          this._bluetoothDeviceWeb = device;
          this._bluetoothConnecting = false;
        });
        pushUpdateContextPropertiesTurntableInfoEvent(this._bluetoothDeviceWeb?.name);
        pushDataLayerEvent({ event: "gemhub:turntable:connected" });
        return this._bluetoothStatus;
      } catch (error) {
        runInAction(() => {
          this._bluetoothStatus = 2;
          this._bluetoothConnecting = false;
        });
        return Promise.reject(error);
      }
    }
  }

  // 获取设备列表
  public async getBuletoothDeviceList() {
    if (isApp) {
      return new Promise<void>((resolve) => {
        appCallbackStore.getBuletoothDeviceList(
          userStore.userMe?.user._id ?? 0,
          this._bluetoothAutoConnect,
          (data) => {
            runInAction(() => {
              const newDeviceList = data.filter((item) => !!item.name) || [];
              this._bluetoothDeviceList = newDeviceList.map((obj) => {
                const update = this._bluetoothDeviceList.find(
                  (originalObj) =>
                    originalObj.state === 1 &&
                    originalObj.identifier === obj.identifier &&
                    obj.state === 0,
                );
                return update ?? obj;
              });
              this._bluetoothDevice =
                this._bluetoothDeviceList.find((item) => item.state === 2) || null;
            });
            resolve();
          },
        );
      });
    } else {
      const bluetoothDeviceListWeb = await navigator.bluetooth.getDevices();
      runInAction(() => {
        this._bluetoothDeviceListWeb = bluetoothDeviceListWeb;
      });
    }
  }

  // 连接指定设备
  public async connectBuletoothDevice(identifier: string) {
    if (isApp) {
      this.abortStopScanBuletoothDevice();
      appCallbackStore.connectBuletoothDevice(identifier, (id) => {
        const bluetoothDevice = this._bluetoothDeviceList.find((item) => item.identifier === id);
        if (bluetoothDevice) {
          pushUpdateContextPropertiesTurntableInfoEvent(bluetoothDevice.name);
          pushDataLayerEvent({ event: "gemhub:turntable:connected" });
        }
      });
    } else {
      await this._bluetoothDeviceWeb?.gatt?.connect();
      pushDataLayerEvent({ event: "gemhub:turntable:connected" });
    }
  }

  // 断开指定设备
  public async disconnectBluetoothDevice(identifier: string) {
    if (isApp) {
      appCallbackStore.disconnectBluetoothDevice(identifier);
    } else {
      await this._bluetoothDeviceWeb?.gatt?.disconnect();
    }
  }

  // 停止扫描设备
  public async stopScanBuletoothDevice(value: number | any) {
    if (isApp) {
      let delay = 0;
      if (typeof value === "number") delay = value;
      if (!this._bluetoothDevice) return;
      runInAction(() => {
        this._stopScanBuletoothDeviceTimerId = setTimeout(() => {
          appCallbackStore.stopScanBuletoothDevice();
        }, 1000 * delay);
      });
    } else {
      await this._bluetoothCharacteristic?.stopNotifications();
    }
  }

  public abortStopScanBuletoothDevice() {
    clearTimeout(this._stopScanBuletoothDeviceTimerId);
  }

  // 控制转盘开始旋转
  public async gemLightBoxStartTurning(direction: number) {
    if (isApp) {
      if (!this._bluetoothDevice) return;
      appCallbackStore.gemLightBoxStartTurning(this._bluetoothDevice!.identifier, direction);
    } else {
      const _direction = direction === 0 ? "left" : "right";
      this.constructBluetoothCommand(_direction, 15, 0);
      await this.sendBluetoothCommandToTurnTable();
    }
  }

  public async gemLightBoxRotate(dir: any, angleX: number, cb: any) {
    const angle: any = this._videoAngle * angleX;
    this.constructBluetoothCommand(dir === 0 ? "left" : "right", angle, this._videoSpeed);
    const finished = (event: Event) => {
      event.target?.removeEventListener("characteristicvaluechanged", finished);
      cb && cb();
    };
    this.listenBluetoothCharacteristicValueChanged(finished);
    await this.sendBluetoothCommandToTurnTable();
  }

  // 转盘按指定模式旋转
  public async gemLightBoxStartRotating(direction = 0): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (isApp) {
        if (!this._bluetoothDevice) return reject(false);
        if (this._videoAngle === 360) {
          appCallbackStore.gemLightBoxStartRotating(
            this._bluetoothDevice!.identifier,
            [this._videoAngle * (direction === 1 ? 1 : -1)],
            this._videoSpeed,
            () => {
              resolve(true);
            },
          );
        } else {
          const angle = this._videoAngle * (direction === 1 ? 1 : -1);
          appCallbackStore.gemLightBoxStartRotating(
            this._bluetoothDevice!.identifier,
            [-angle, angle * 2, -angle],
            this._videoSpeed,
            () => {
              resolve(true);
            },
          );
        }
      } else {
        if (this._videoAngle === 360) {
          this.gemLightBoxRotate(direction, 1, () => {
            return resolve(true);
          });
        } else {
          this.gemLightBoxRotate(1 - direction, 1, () => {
            this.gemLightBoxRotate(direction, 2, () => {
              this.gemLightBoxRotate(1 - direction, 1, () => {
                return resolve(true);
              });
            });
          });
        }
      }
    });
  }

  // 获取系统信息
  // public async getEnvironmentInfo() {
  //   if (isApp) {
  //     appCallbackStore.getEnvironmentInfo((data) => {
  //     });
  //   }
  // }

  public play() {
    if (this._animationFrameId) window.cancelAnimationFrame(this._animationFrameId);

    const video = this.videoRef.current;
    const component = this.mainImageComponent;
    if (
      !video ||
      !component ||
      !this._mediaStream ||
      !this._track ||
      !video.videoWidth ||
      !video.videoHeight
    ) {
      return;
    }
    component.state.texture.loadSyncSource(video);
    this.renderer.render();
    this._animationFrameId = window.requestAnimationFrame(this.play);
  }

  public async checkAndPlayStream() {
    if (this._mediaStream && this._track) return;

    runInAction(() => {
      this._connectionStatus = "pending";
    });

    const AVCamera = await this.requeseCamera();
    const connectedDevice = AVCamera;
    if (!connectedDevice) return this.stopCamera();
    return await this._enableVideoStream(connectedDevice.deviceId);
    // this.play();
  }

  public async requeseCamera() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true,
      });

      const tracks = stream.getVideoTracks();
      tracks.forEach((track) => track.stop());
    } catch (error) {
      console.error(error);
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    const AVCamera = devices.find(
      (device) =>
        device.kind === "videoinput" &&
        this.CAMERA_NAME.some((name) => device.label.startsWith(name)),
    );
    runInAction(() => {
      const label = AVCamera?.label || "";
      this.name = label.replace(/\([0-9a-fA-F]{4}:[0-9a-fA-F]{4}\)/g, "").trim();
    });

    return AVCamera;
  }

  public getResolutionByCameraResolutionValue(value: CameraResolutionType) {
    const [width, height] =
      this._resolutionMode === "marketplace"
        ? marketplaceDimensionsValues[value as keyof typeof marketplaceDimensionsValues]
        : photoResolutionValues[value as keyof typeof photoResolutionValues];
    return [width, height];
  }

  public findFirstSmallerByCameraResolutionValue(value: CameraResolutionType) {
    const [width, height] = this.getResolutionByCameraResolutionValue(value);
    const totalPixels = width * height;
    const array = Object.values(photoResolutionValues);
    let maxSmallerW = 0;
    let maxSmallerH = 0;
    array.forEach(([w, h]) => {
      if (w * h < totalPixels) {
        if (w * h > maxSmallerW * maxSmallerH) {
          maxSmallerW = w;
          maxSmallerH = h;
        }
      }
    });
    return [maxSmallerW, maxSmallerH];
  }

  public async changeResolution(value: CameraResolutionType, captureMode: CaptureModeType) {
    this._captureMode = captureMode;
    if (captureMode === "video") {
      this._resolutionVideo = value;
    } else {
      this._resolutionPhoto = value;
    }

    captureModeStorage.set({
      captureMode,
      resolutionVideo: this._resolutionVideo,
      resolutionPhoto: this._resolutionPhoto,
    });

    if (this._track) {
      const [width, height] = this.getResolutionByCameraResolutionValue(value);
      await this._track.applyConstraints({
        frameRate: { min: 25, ideal: 30 },
        width: { ideal: width },
        height: { ideal: height },
        aspectRatio: { ideal: width / height },
      });

      this.renderer.setCanvas(width, height);
      this.mainImageComponent.transform.setSize(width, height);
      this.setMainImageComponentScale(1);
      this.rendererLogoWatermark();
    }
  }

  public setMainImageComponentScale(value: number) {
    runInAction(() => {
      const camera = this.camera;
      this.mainImageComponent.transform.setScale(value, value);
      const viewSize = camera.translation.add(camera.projection.divide(camera.scale));
      const median = camera.translation.median(viewSize);
      const newWidth = viewSize.width * value;
      const newHeight = viewSize.height * value;
      const newX = median.x - newWidth / 2;
      const newY = median.y - newHeight / 2;
      this.mainImageComponent.transform.setTranslation(newX, newY);
      this._mainImageComponentScale = value;
    });
  }

  public setFilters(filters: any) {
    this.mainImageComponent.state.filters = filters;
  }

  public changeFilter(filter: SupportedAdjustments, value: number) {
    this.mainImageComponent.state.filters[filter] = value;
    this.setCameraSettingsLocalStorageByFilterValue();
  }

  public resetFilters(filters: any) {
    this.mainImageComponent?.resetFilters(filters);
    this.setCameraSettingsLocalStorageByFilterValue();
  }

  public handleStartRecord(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this._isRecording || !this._track) return resolve(false);

      const mimeTypes = [
        // "video/webm;codecs=vp9",
        "video/webm;codecs=avc1",
        "video/mp4;codecs=avc1",
      ];
      let mimeType;
      for (let i = 0; i < mimeTypes.length; i++) {
        if (MediaRecorder.isTypeSupported(mimeTypes[i])) {
          mimeType = mimeTypes[i];
          // eslint-disable-next-line no-console
          console.log("video mimeType: ", mimeType);
          break;
        }
      }
      const options: MediaRecorderOptions = {};
      mimeType && (options.mimeType = mimeType);
      let fps = parseInt(String(this._track.getSettings().frameRate || DEFAULT_CAPTURE_FRAME_RATE));
      fps = fps > 30 ? 30 : fps;

      if (window.$platform.os === "Win" && this.recommendedResolutions.length > 0) {
        const [width, height] = this.getResolutionByCameraResolutionValue(this._resolutionVideo);
        if (this.recommendedResolutions[0] * this.recommendedResolutions[1] >= width * height) {
          options.videoBitsPerSecond = ((width * height) / (1200 * 1200)) * 10000000;
        }
      }

      // eslint-disable-next-line no-console
      console.log("video videoBitsPerSecond: ", options.videoBitsPerSecond, "fps:", fps);
      const canvasStream = this.renderer.canvas.captureStream();
      this._mediaRecorder = new MediaRecorder(canvasStream, options);

      this._mediaRecorder.addEventListener(
        "start",
        () => {
          pushDataLayerEvent({ event: "gemhub:camera:capture:video:started" });
          runInAction(() => {
            this._isRecording = true;
          });
          resolve(true);
        },
        { once: true },
      );

      this._mediaRecorder?.start();
    });
  }

  public handleStopRecord(): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      if (!this._isRecording)
        return reject(new Error("handleStopRecord: camera is not recording."));

      this._mediaRecorder?.addEventListener(
        "dataavailable",
        (event) => {
          if (!event.data.size)
            return reject("dataavailable event did not capture any video stream.");
          // const type = MediaRecorder.isTypeSupported("video/mp4;codecs=avc1") ? "video/mp4" : "video/webm";
          // const videoBlob = event.data.slice(0, event.data.size, "video/mp4");
          resolve(event.data);
        },
        { once: true },
      );

      this._mediaRecorder?.addEventListener(
        "stop",
        () => {
          runInAction(() => {
            this._isRecording = false;
            this._recordedTime = 0;
          });
        },
        { once: true },
      );

      this._mediaRecorder?.stop();
    });
  }

  public stopCamera() {
    runInAction(() => {
      this._connectionStatus = "notConnected";
      this._track?.stop();
      this._track = null;
      this._mediaStream = null;
      this.videoRef.current?.pause();
      window.cancelAnimationFrame(this._animationFrameId);
      this._animationFrameId = 0;
      if (this.videoRef.current) this.videoRef.current.srcObject = null;
    });
  }

  public setGemcamPreviewVideo(value: CameraMediaType | null) {
    this._gemcamPreviewVideo = value;
  }

  public setBurstMode(value: boolean) {
    this._isBurstMode = value;
  }

  public setLogoWatermarkMode(value: boolean) {
    localStorage.setItem(cameraLogoWatermarkModeLocalStorageEntity, value.toString());
    this._isLogoWatermarkMode = value;
  }

  public setGemAIActive(value: boolean) {
    this._gemAIActive = value;
  }

  public setResolutionMode(mode: "marketplace" | "regular") {
    this._resolutionMode = mode;
  }

  public setBluetoothAutoConnect(value: boolean) {
    const data = cameraStorage.get();
    cameraStorage.set({ ...data, bluetoothAutoConnect: value });
    this._bluetoothAutoConnect = value;
  }

  public setIsGeneratedSkuTutorialOpened(value: boolean) {
    const data = cameraStorage.get();
    cameraStorage.set({ ...data, isGeneratedSkuTutorialOpened: value });
    runInAction(() => {
      this._isGeneratedSkuTutorialOpened = value;
    });
  }

  public setIsNoSaveProductInfo(value: boolean) {
    const data = cameraStorage.get();
    cameraStorage.set({ ...data, isNoSaveProductInfo: value });
    runInAction(() => {
      this._isNoSaveProductInfo = value;
    });
  }

  public setIsFilterSceneTutorialOpened(value: boolean) {
    const data = cameraStorage.get();
    cameraStorage.set({ ...data, isFilterSceneTutorialOpened: value });
    runInAction(() => {
      this._isFilterSceneTutorialOpened = value;
    });
  }

  public resetGemAIData() {
    this._gemAIData = gemAIMap;
  }

  public setGemAIData(key: GemAIMapKeys, value: boolean) {
    this._gemAIData[key][this._filterValue].value = value;
    this.setCameraSettingsLocalStorage({
      filterValue: this._filterValue,
      gemAIData: this._gemAIData,
    });
  }

  public setFilterValue(value: FilterMode) {
    this._filterValue = value;
    this.setCameraSettingsLocalStorage({ filterValue: value });
    localStorage.setItem(cameraFilterModeLocalStorageEntity, String(value));
  }

  public setFilterScenarioValue(value: FilterScenarioMode) {
    this._filterScenarioValue = value;
    this.setCameraSettingsLocalStorage({
      filterValue: this._filterValue,
      filterScenarioValue: this._filterScenarioValue,
    });
  }

  public setPresetMode(value: GemAIMapKeys) {
    const theOtherMode = value === "background" ? "retouch" : "background";
    this.setGemAIData(value, this.presetMode === value ? false : true);
    this.setGemAIData(theOtherMode, false);
  }

  public setVideoSpeed(value: TurnTableSpeedControlKeys) {
    this._videoSpeed = value;
    this.setCameraSettingsLocalStorage({
      filterValue: this._filterValue,
      videoSpeed: this._videoSpeed,
    });
  }

  public setVideoAngle(value: TurnTableDegreesControlKeys) {
    this._videoAngle = value;
    this.setCameraSettingsLocalStorage({
      filterValue: this._filterValue,
      videoAngle: this._videoAngle,
    });
  }

  public setDpi(value: number) {
    localStorage.setItem(cameraDpiLocalStorageEntity, value.toString());
    this._dpi = value;
  }

  public setRecommendedResolutions(width: number, height: number) {
    runInAction(() => {
      this._recommendedResolutions = [width, height];
    });
    localStorage.setItem("recommendedResolutions", JSON.stringify(this._recommendedResolutions));
  }

  public constructBluetoothCommand(
    direction: TurnTableDirectionControlKeys,
    degrees: TurnTableDegreesControlKeys,
    speed: TurnTableSpeedControlKeys,
  ) {
    this._bluetoothCommand =
      turnTableDirectionControl[direction] +
      turnTableDegreesControl[degrees] +
      turnTableSpeedControl[speed];
  }

  public sendBluetoothCommandToTurnTable = async () => {
    if (!this._bluetoothDeviceWeb || !this._bluetoothCharacteristic || !this._bluetoothCommand)
      return;

    const encoder = new TextEncoder();
    const value = encoder.encode(this._bluetoothCommand);
    await this._bluetoothCharacteristic.writeValue(value);
  };

  public listenBluetoothCharacteristicValueChanged = (cb: any) => {
    if (!this._bluetoothDeviceWeb || !this._bluetoothCharacteristic || !this._bluetoothCommand)
      return;

    this._bluetoothCharacteristic.addEventListener("characteristicvaluechanged", cb);
  };

  public rendererLogoWatermark = () => {
    const removeComponent = () => {
      const mainImageComponent = this.renderer.components[0];
      const components = this.renderer.components.filter((f) => f.id !== mainImageComponent.id);
      for (const component of components) {
        this.renderer.removeComponent(component);
      }
    };

    if (this.isLogoWatermarkMode) {
      Webgl2New.Webgl2.gl.enable(Webgl2New.Webgl2.gl.BLEND);
      Webgl2New.Webgl2.gl.blendFuncSeparate(
        Webgl2New.Webgl2.gl.SRC_ALPHA,
        Webgl2New.Webgl2.gl.ONE_MINUS_SRC_ALPHA,
        Webgl2New.Webgl2.gl.ONE,
        Webgl2New.Webgl2.gl.ONE_MINUS_SRC_ALPHA,
      );

      runInAction(async () => {
        this.logoWatermarkImageComponent =
          await logoWatermarkModalStore.rendererLogoWatermarkByCameraRenderer(
            this.renderer.canvasSize,
          );

        removeComponent();
        if (this.logoWatermarkImageComponent) {
          for (const component of this.logoWatermarkImageComponent) {
            this.renderer.addComponent(component);
          }
        }
      });
    } else {
      removeComponent();
    }
  };

  public logoWatermarkDisplay = (display: "initial" | "none") => {
    let isRun = !!this.presetMode && !this.openEclipse;
    // Should the capture mode be set to video, 'isRun' is designated as true solely under the condition that both preset and burst modes are concurrently activated.
    if (this.captureMode === "video")
      isRun = this.isBurstMode && !!this.presetMode && !this.openEclipse;
    if (!userStore.isSubscribed) isRun = false;

    if (isRun && this.logoWatermarkImageComponent) {
      for (const component of this.logoWatermarkImageComponent) {
        component.style.display = display;
      }
      this.renderer?.renderComponents();
    }
  };

  public setLogoWatermarkToImage = async (url: string) => {
    if (!this.isLogoWatermarkMode) return url;
    return await logoWatermarkModalStore.setLogoWatermarkToImage(url);
  };

  private _initStore() {
    this._isBurstMode = true;
    this._gemAIActive = true;
    this._isBlockCamera = false;
    this._bluetoothAutoConnect = cameraStorage.get().bluetoothAutoConnect;
    this._bluetoothStatus = 0;
    this._videoSpeed = 0;
    this._videoAngle = 360;
    this._resolutionVideo = "2160";
    this._resolutionPhoto = "2160";
    this._focusMode = "continuous";
    this._resolutionMode = "regular";
    this._connectionStatus = "pending";
    //R - right, 360 - degrees, 9 - fast speed
    this._bluetoothCommand = "R3609";
    this._recordedTime = 0;
    this._isRecording = false;
    this.videoRef = { current: null };
    this._videoConstraints = { frameRate: { min: 25, ideal: 30 } };
    this._focusDistance = undefined as any;
    this.renderer = null as any;
    this._track = null as any;
    this._mediaStream = null as any;
    this._animationFrameId = 0;
    this.mainImageComponent = null as any;
    this._mediaRecorder = null;
    this._bluetoothDeviceList = [];
    this._bluetoothDeviceListWeb = [];
    this._parentRef = null;
    this._bluetoothConnecting = false;
    localStorage.setItem(isFirstEnterGemCamLocalKey, "false");
    this._filterScenarioValue = FilterScenarioMode.Default;
    this._gemAIData = gemAIMap;
    this._gemcamPreviewVideo = null;
    this._resDetPctVal = 0;
    this._isGeneratedSkuTutorialOpened = cameraStorage.get().isGeneratedSkuTutorialOpened;
    this._isNoSaveProductInfo = cameraStorage.get().isNoSaveProductInfo;
    this._isFilterSceneTutorialOpened = cameraStorage.get().isFilterSceneTutorialOpened;

    const localSaveSettings = localSaveSettingsStorage.get();
    if (!isDeepEmpty(localSaveSettings)) {
      runInAction(() => {
        localSaveSettings.folderPath = JsCrypto.AES.decrypt(
          localSaveSettings.folderPath,
          cameraLocalSaveSettingsStorageEntity,
        ).toString(JsCrypto.enc.Utf8);
        this._localSaveSettings = { ...this._localSaveSettings, ...localSaveSettings };
      });
    }
    this.handleInitCameraSettingsByLocalStorage();
  }

  public setCameraSettingsLocalStorage(cameraSettingsSlice: CameraSettingsType) {
    let cameraSettingsLocalStorage = new LocalStorage(
      cameraSettingsLocalStorageEntity + cameraSettingsSlice.filterValue,
    );
    const cameraSettings = cameraSettingsLocalStorage.get() as CameraSettingsType;
    if (cameraSettings.cameraFilters && cameraSettingsSlice.cameraFilters) {
      cameraSettingsSlice.cameraFilters = {
        ...cameraSettings.cameraFilters,
        ...cameraSettingsSlice.cameraFilters,
      };
    }
    cameraSettingsLocalStorage.set({ ...cameraSettings, ...cameraSettingsSlice });
    cameraSettingsLocalStorage = new LocalStorage(
      cameraSettingsLocalStorageEntity + cameraSettingsSlice.filterValue,
    );
  }

  public removeCameraSettingsLocalStorage(filterValue: FilterMode) {
    const cameraSettingsLocalStorage = new LocalStorage(
      cameraSettingsLocalStorageEntity + filterValue,
    );
    cameraSettingsLocalStorage.destroy();
  }

  public setCameraSettingsLocalStorageByFilterValue() {
    let filters;
    if (this.filterScenarioValue === FilterScenarioMode.Default) {
      filters = { [FilterMode[this.filterValue]]: this.mainImageComponent.state.filters };
    } else {
      filters = {
        [FilterScenarioMode[this.filterScenarioValue]]: this.mainImageComponent.state.filters,
      };
    }
    if (this.filterValue === FilterMode.GemLightboxPro && this.openEclipse) {
      filters = {
        [FilterMode[FilterMode.Eclipse]]: this.mainImageComponent.state.filters,
      };
    }

    this.setCameraSettingsLocalStorage({
      filterValue: this.filterValue,
      filterScenarioValue: this.filterScenarioValue,
      cameraFilters: filters,
    });
  }

  public getCameraSettingsLocalStorage() {
    const cameraSettingsLocalStorage = new LocalStorage(
      cameraSettingsLocalStorageEntity + this.filterValue,
    );
    const cameraSettings = cameraSettingsLocalStorage.get() as CameraSettingsType;
    return cameraSettings;
  }

  public handleInitCameraSettingsByLocalStorage() {
    const cameraSettings = this.getCameraSettingsLocalStorage();
    if (!isDeepEmpty(cameraSettings)) {
      this._cameraSettings = cameraSettings;
      this._filterScenarioValue = cameraSettings.filterScenarioValue ?? FilterScenarioMode.Default;
      this._gemAIData = cameraSettings.gemAIData ?? gemAIMap;
      this._videoAngle = cameraSettings.videoAngle ?? 360;
      this._videoSpeed = cameraSettings.videoSpeed ?? 0;
      this._openEclipse = cameraSettings.openEclipse ?? false;

      return true;
    }
    return false;
  }

  public handleInitCameraFiltersByLocalStorage(defaulFtilter: any) {
    const cameraSettings = this.getCameraSettingsLocalStorage();
    if (isDeepEmpty(cameraSettings)) {
      this.mainImageComponent?.resetFilters(defaulFtilter);
      return true;
    }

    if (cameraSettings.cameraFilters === undefined) {
      this.mainImageComponent?.resetFilters(defaulFtilter);
      return true;
    }

    let filterValueKey;
    if (
      cameraSettings.filterScenarioValue === undefined ||
      cameraSettings.filterScenarioValue === FilterScenarioMode.Default
    ) {
      filterValueKey = FilterMode[cameraSettings.filterValue] as FilterModeKeys;
    } else {
      filterValueKey = FilterScenarioMode[cameraSettings.filterScenarioValue] as FilterModeKeys;
    }

    if (cameraSettings.filterValue === FilterMode.GemLightboxPro && cameraSettings.openEclipse) {
      filterValueKey = FilterMode[FilterMode.Eclipse] as FilterModeKeys;
    }

    const filter = cameraSettings.cameraFilters[filterValueKey];
    if (filter) {
      this.mainImageComponent?.resetFilters(filter);
      return true;
    } else {
      if (defaulFtilter) {
        this.mainImageComponent?.resetFilters(defaulFtilter);
        return true;
      }
    }
    return false;
  }

  private _openEclipse = false;
  public get openEclipse() {
    return this._openEclipse;
  }
  public setOpenEclipse(value: boolean) {
    this._openEclipse = value;
    this.setCameraSettingsLocalStorage({
      filterValue: this.filterValue,
      openEclipse: value,
    });
  }

  private _handleInitializeWebgl2() {
    // Main Image Component ↓
    const mainImageTexture = new Webgl2New.Texture();
    const mainImageComponent = new Webgl2New.ImageAdjustRectComponent(mainImageTexture);
    this.mainImageComponent = mainImageComponent;
    mainImageComponent.transform.setSize(this.renderer.canvasSize);
    this.renderer.addComponent(mainImageComponent);
    // Main Image Component ↑
    this.rendererLogoWatermark();
  }

  private _onLoadedMetadata: (this: HTMLVideoElement, ev: Event) => void;
  private async _enableVideoStream(deviceId: string) {
    if (!this.videoRef.current) return this.stopCamera();
    try {
      const resolution = this.captureMode == "video" ? this.resolutionVideo : this.resolutionPhoto;
      const [width, height] =
        this._resolutionMode === "marketplace"
          ? marketplaceDimensionsValues[resolution as keyof typeof marketplaceDimensionsValues]
          : photoResolutionValues[resolution as keyof typeof photoResolutionValues];

      Object.assign(this._videoConstraints, {
        deviceId,
      });

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          ...this._videoConstraints,
          deviceId,
          width: { ideal: width },
          height: { ideal: height },
          aspectRatio: { ideal: width / height },
        },
      });
      const tracks = stream.getVideoTracks();
      if (tracks.length === 0) return this.stopCamera();
      const track = tracks[0];
      track.removeEventListener("ended", this.stopCamera);
      track.addEventListener("ended", this.stopCamera);
      return new Promise<void>((resolve, reject) => {
        if (!this.videoRef?.current)
          return reject("In _enableVideoStream, calling this.videoRef.current returns null.");

        this.videoRef.current.removeEventListener("loadedmetadata", this._onLoadedMetadata);
        this._onLoadedMetadata = (e: Event) => {
          (e.currentTarget as HTMLVideoElement).play();
          runInAction(() => {
            this._mediaStream = stream;
            this._track = track;
            this._connectionStatus = "connected";
            this.cameraDeviceConnected();
          });
          resolve();
        };

        this.videoRef.current.addEventListener("loadedmetadata", this._onLoadedMetadata);

        this.videoRef.current.srcObject = stream;
      });
    } catch (error) {
      console.error(`error`, error);
      this.stopCamera();
    }
  }

  public setBestResolution() {
    runInAction(() => {
      if (this.captureModeStorageData?.resolutionVideo)
        this._resolutionVideo = this.captureModeStorageData.resolutionVideo;
      if (this.captureModeStorageData?.resolutionPhoto)
        this._resolutionPhoto = this.captureModeStorageData.resolutionPhoto;

      this._connectionStatus = "pending";
    });

    if (
      this.captureModeStorageData?.captureMode === "video" &&
      this.captureModeStorageData?.resolutionVideo
    )
      return;

    if (this.recommendedResolutions.length == 0) return;
    runInAction(() => {
      this._resolutionVideo = `${this.recommendedResolutions[0]}` as CameraResolutionType;
      this._connectionStatus = "pending";
    });
  }

  public async recheckResolution() {
    if (this._isRecording || !this._track) return;

    const mimeTypes = ["video/webm;codecs=avc1", "video/mp4;codecs=avc1"];
    let mimeType;
    for (let i = 0; i < mimeTypes.length; i++) {
      if (MediaRecorder.isTypeSupported(mimeTypes[i])) {
        mimeType = mimeTypes[i];
        // eslint-disable-next-line no-console
        console.log("video mimeType: ", mimeType);
        break;
      }
    }
    const options: MediaRecorderOptions = {};
    mimeType && (options.mimeType = mimeType);

    const [width, height] = this.getResolutionByCameraResolutionValue(this._resolutionVideo);
    options.videoBitsPerSecond = ((width * height) / (1200 * 1200)) * 10000000;
    runInAction(() => {
      this._resDetPctVal = 0;
      this._connectionStatus = "testFrameRateing";
    });

    const canvasStream = this.renderer.canvas.captureStream();
    const setIntervalId = setInterval(() => {
      runInAction(() => {
        this._resDetPctVal < 90 && (this._resDetPctVal += 5);
      });
    }, 500);
    this.recordTestData(canvasStream, options, 5500).then(async (blob) => {
      runInAction(async () => {
        this._resDetPctVal = 60;
      });
      const fps = await this.testFrameRate(blob);
      if (fps >= 25) {
        this.setRecommendedResolutions(width, height);
      }
      clearInterval(setIntervalId);
      runInAction(async () => {
        this._resDetPctVal = 100;
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      runInAction(async () => {
        this._connectionStatus = "connected";
      });
    });
  }

  public async recordTestDataForAllResolutions() {
    runInAction(() => {
      this._resDetPctVal = 0;
    });

    const AVCamera = await this.requeseCamera();
    if (AVCamera === undefined) return;
    if (this.recommendedResolutions.length > 0) return;
    const mimeTypes = ["video/webm;codecs=avc1", "video/mp4;codecs=avc1"];
    let mimeType;
    for (let i = 0; i < mimeTypes.length; i++) {
      if (MediaRecorder.isTypeSupported(mimeTypes[i])) {
        mimeType = mimeTypes[i];
        break;
      }
    }

    const options: MediaRecorderOptions = {};
    mimeType && (options.mimeType = mimeType);
    const videoResolutions = [];
    for (const key in videoResolutionValues) {
      const [width, height] = videoResolutionValues[key as keyof typeof videoResolutionValues];
      if (width === height) {
        videoResolutions.push(width);
      }
    }

    for (const [index, width] of videoResolutions.sort((a, b) => b - a).entries()) {
      const height = width;
      options.videoBitsPerSecond = ((width * height) / (1200 * 1200)) * 16000000;
      // eslint-disable-next-line no-console
      console.log("video mimeType, width, height: ", mimeType, width, height);
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            deviceId: AVCamera!.deviceId,
            width: { ideal: width },
            height: { ideal: height },
            frameRate: { min: 25, ideal: 30 },
          },
        });

        runInAction(() => {
          this._connectionStatus = "testFrameRateing";
        });
        // 获取视频轨道
        const videoTrack = stream.getVideoTracks()[0];
        // 尝试设置手动对焦模式
        await videoTrack.applyConstraints({
          advanced: [{ focusMode: "manual" }] as any,
        });
        const videoElement = document.createElement("video");
        videoElement.autoplay = true;
        const canvas = document.createElement("canvas") as HTMLCanvasElement;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        videoElement.srcObject = stream;
        videoElement.load();
        videoElement.play();
        let animationFrameId = 0;
        let runRraw = true;
        const draw = () => {
          if (!runRraw) return;
          ctx.drawImage(videoElement, 0, 0, width, width);
          animationFrameId = window.requestAnimationFrame(draw);
        };
        draw();
        const newStream = canvas.captureStream(30);
        runInAction(() => {
          this._resDetPctVal = this._resDetPctVal + 5;
        });
        const blob = await this.recordTestData(newStream, options, 5000);
        runRraw = false;
        window.cancelAnimationFrame(animationFrameId);
        const tracks = stream.getVideoTracks();
        tracks.forEach((track) => track.stop());
        const fps = await this.testFrameRate(blob);
        runInAction(() => {
          this._resDetPctVal = ((index + 1) * 100) / videoResolutions.length;
        });
        if (fps >= 26) {
          this.setRecommendedResolutions(width, height);
          break;
        } else if (videoResolutions.length == index + 1) {
          this.setRecommendedResolutions(width, height);
        }
      } catch (error) {
        console.warn(error);
        break;
      }
    }

    runInAction(async () => {
      this._resDetPctVal = 100;
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  public async recordTestData(stream: MediaStream, options: MediaRecorderOptions, ms = 3500) {
    return new Promise<Blob>((resolve) => {
      const mediaRecorder = new MediaRecorder(stream, options);
      let videoData: Array<Blob> = [];
      mediaRecorder.addEventListener("dataavailable", (event) => {
        if (!event.data.size) return;
        videoData.push(event.data);
      });

      // 录制停止事件
      mediaRecorder.addEventListener("stop", async () => {
        const blob = new Blob(videoData, { type: options.mimeType });
        videoData = [];
        resolve(blob.slice(0, blob.size, blob.type));
      });
      setTimeout(() => {
        mediaRecorder.stop();
      }, ms);
      mediaRecorder.start();
    });
  }

  public async testFrameRate(blob: Blob) {
    return new Promise<number>((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.autoplay = true;
      videoElement.src = URL.createObjectURL(blob);
      videoElement.load();
      let lastTime = performance.now();
      let frameCount = 0;
      const arrayFps: number[] = [];

      const cleanupAndResolve = (fps: number) => {
        videoElement.removeEventListener("ended", onEnded);
        videoElement.remove();
        URL.revokeObjectURL(videoElement.src);
        // eslint-disable-next-line no-console
        console.log("fps:", fps);
        resolve(fps);
      };

      const onEnded = () => {
        if (arrayFps.length > 2) {
          arrayFps.sort(function (a, b) {
            return a - b;
          });
          arrayFps.shift();
          arrayFps.pop();
        }
        cleanupAndResolve(arrayFps.reduce((a, b) => a + b, 0) / arrayFps.length);
        // cleanupAndResolve(Math.max(...arrayFps));
      };

      videoElement.addEventListener("ended", onEnded);

      videoElement.play().catch((error) => {
        console.error("Video playback failed:", error);
        cleanupAndResolve(0);
      });

      // 检测浏览器是否支持requestVideoFrameCallback
      if ("requestVideoFrameCallback" in HTMLVideoElement.prototype) {
        const calculateFrameRate = (
          now: DOMHighResTimeStamp,
          metadata: VideoFrameCallbackMetadata,
        ) => {
          frameCount++;
          const diff = now - lastTime;
          if (diff >= 1000) {
            const fps = frameCount / (diff / 1000);
            // eslint-disable-next-line no-console
            console.log(
              "current fps:",
              fps,
              " width:",
              metadata.width,
              " height:",
              metadata.height,
            );
            arrayFps.push(fps);
            if (fps < 5) {
              cleanupAndResolve(fps);
              return;
            }
            frameCount = 0;
            lastTime = now;
          }
          videoElement.requestVideoFrameCallback(calculateFrameRate);
        };
        videoElement.requestVideoFrameCallback(calculateFrameRate);
      } else {
        reject(new Error("The browser does not support requestVideoFrameCallback"));
      }
    });
  }
}

export const cameraStore = new CameraStore();
