import React, { useMemo } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { Button, Typography, SvgIcon, useWindowSize } from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { BottomPanel } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { exportProductsCSV } from "src/api";
import { downloadVideo } from "src/utils";

import { ReactComponent as CsvSVG } from "src/external-ts/assets/images/file-csv-grey.svg";
import { ReactComponent as CloseIcon } from "src/external-ts/assets/images/cross-grey.svg";
import { ReactComponent as MoveSVG } from "src/external-ts/assets/images/catalog/add-folder-grey.svg";
import { ReactComponent as CopySVG } from "src/external-ts/assets/images/copy-grey.svg";
import { ReactComponent as DownloadSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./catalog-details-action-panel.module.css";

export const CatalogDetailsActionsPanel: React.FC = observer(() => {
  const { windowWidth } = useWindowSize();
  const { catalogStore, modalsStore, localeStore, notificationStore } = useStores();

  const selectedProducts = catalogStore.selectedProductList;
  const selectedAmount = catalogStore.selectedProductAmount;

  const hasAllSelected = catalogStore.productsAmount === selectedAmount;
  const isAllCatalog = catalogStore.catalog?.is_all;
  const isSpecialCatalog = isAllCatalog || catalogStore.catalog?.isRapnetCatalog;

  const shouldRenderButtonText = catalogStore.isAutoCatalog
    ? windowWidth >= BREAKPOINTS.tablet
    : windowWidth >= 1260;
  const isMobile = windowWidth <= BREAKPOINTS.mobileL;

  const disableMoveAction = isSpecialCatalog || catalogStore.isAutoCatalog;
  const disableDeleteAction = isAllCatalog || catalogStore.isAutoCatalog;

  const isDownloadButtonDisabled = useMemo(() => {
    return !selectedProducts.filter((product) => product.images.length).length;
  }, [selectedProducts]);

  const handleClose = () => catalogStore.unselectAllProductList("selected");

  const handleCopy = () => {
    modalsStore.open("AddToCatalogSidebar", {
      type: "assign",
      title: localeStore.t(
        '["catalog-details"]["catalog-details-actions-panel"].modals.copy.title',
      ),
      confirmText: localeStore.t(
        '["catalog-details"]["catalog-details-actions-panel"].modals.copy["confirm-text"]',
      ),
      products: toJS(catalogStore.selectedProductList),
    });
  };

  const handleMove = () => {
    modalsStore.open("AddToCatalogSidebar", {
      type: "reassign",
      title: localeStore.t(
        '["catalog-details"]["catalog-details-actions-panel"].modals.move.title',
      ),
      confirmText: localeStore.t(
        '["catalog-details"]["catalog-details-actions-panel"].modals.move["confirm-text"]',
      ),
      products: toJS(catalogStore.selectedProductList),
      onSubmit: () => {
        if (!catalogStore.catalog) return;
        catalogStore.loadCatalog(catalogStore.catalog.id);
      },
    });
  };

  const handleSelectAll = () => catalogStore.selectAllProductList("selected");

  const handleDownload = () => {
    const isNotOnlyVideo = selectedProducts.some((product) => {
      return product.images[0].type === "image" || product.images[0].type === "blink";
    });

    if (!isNotOnlyVideo) {
      const productsIds = selectedProducts.reduce((productsImageIds: number[], product) => {
        const imageIds = product.images.map(({ id }) => id);
        return [...productsImageIds, ...imageIds];
      }, []);
      downloadVideo(productsIds, selectedProducts[0]);
      return;
    }
    modalsStore.open("DownloadMediaModal", { media: selectedProducts, type: "products" });
    handleClose();
  };

  const handleExportCSV = () => {
    const ids = catalogStore.selectedProductList.map(({ _id }) => _id);

    handleClose();
    exportProductsCSV(ids, catalogStore.catalog?.id.toString());
  };

  const handleDelete = () => {
    notificationStore.open({
      title: localeStore.t(
        '["catalog-details"]["catalog-details-actions-panel"].modals.delete.title',
      ),
      confirmText: localeStore.t(
        '["catalog-details"]["catalog-details-actions-panel"].modals.delete["confirm-text"]',
      ),
      cancelText: localeStore.t("common.buttons.cancel"),
      onOk: () => {
        handleClose();
        catalogStore.unassignProducts(selectedProducts);
      },
    });
  };

  if (!catalogStore.productsAmount) return null;

  return (
    <BottomPanel
      className={styles.panelWrapper}
      contentClassName={styles.panelContent}
      isOpened={!!selectedAmount}
    >
      <div className={styles.left}>
        <Button appearance="tertiaryOutlined" size="small" onClick={handleClose}>
          <SvgIcon icon={CloseIcon} />
        </Button>
        <div className={styles.selectedItems}>
          <Typography size={isMobile ? "extraSmall500" : "small600"} color="textSecondary">
            {selectedAmount}
          </Typography>
          <Typography size={isMobile ? "extraSmall" : "small"} color="textTertiary">
            {localeStore.t('["catalog-details"]["catalog-details-actions-panel"].selected')}
          </Typography>
        </div>
        {!isMobile && (
          <Button
            className={styles.selectAllButton}
            appearance={hasAllSelected ? "tertiaryOutlined" : "primaryOutlined"}
            size="small"
            onClick={hasAllSelected ? handleClose : handleSelectAll}
          >
            {hasAllSelected
              ? localeStore.t(
                  '["catalog-details"]["catalog-details-actions-panel"].buttons["unselect-all"]',
                )
              : localeStore.t(
                  '["catalog-details"]["catalog-details-actions-panel"].buttons["select-all"]',
                )}
          </Button>
        )}
      </div>

      <div className={styles.right}>
        <Button appearance="primaryGhost" onClick={handleExportCSV}>
          <SvgIcon icon={CsvSVG} />
          {shouldRenderButtonText &&
            localeStore.t('["catalog-details"]["catalog-details-actions-panel"].buttons.export')}
        </Button>
        {!disableMoveAction && (
          <Button appearance="primaryGhost" onClick={handleMove}>
            <SvgIcon icon={MoveSVG} />
            {shouldRenderButtonText &&
              localeStore.t('["catalog-details"]["catalog-details-actions-panel"].buttons.move')}
          </Button>
        )}
        <Button appearance="primaryGhost" onClick={handleCopy}>
          <SvgIcon icon={CopySVG} />
          {shouldRenderButtonText &&
            localeStore.t('["catalog-details"]["catalog-details-actions-panel"].buttons.copy')}
        </Button>
        <Button
          appearance="secondaryGhost"
          onClick={handleDownload}
          disabled={isDownloadButtonDisabled}
        >
          <SvgIcon icon={DownloadSVG} />
          {shouldRenderButtonText &&
            localeStore.t('["catalog-details"]["catalog-details-actions-panel"].buttons.download')}
        </Button>
        {!disableDeleteAction && (
          <Button appearance="errorGhost" onClick={handleDelete}>
            <SvgIcon icon={DeleteSVG} />
            {shouldRenderButtonText &&
              localeStore.t('["catalog-details"]["catalog-details-actions-panel"].buttons.delete')}
          </Button>
        )}
      </div>
    </BottomPanel>
  );
});
